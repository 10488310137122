import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form/contact-form";
import HgvAddress from "../components/hgv-address/hgv-address";

const KontaktPage = (props) => {
    const formSuccess = props.location.hash === "#success";

    return(
        <Layout>
            <SEO title="Kontakt"/>
            <div className={'contactPage container section bottom'}>

                <h1 className={'title'}>Kontakt</h1>
                <h4 className={'subtitle'}>Ihre Kontaktanfrage</h4>

                    {formSuccess ? (<h2>Ihre Nachricht wurde erfolgreich gesendet.</h2>) : (<ContactForm></ContactForm>)}
                    <HgvAddress></HgvAddress>
            </div>
        </Layout>
    )
};

export default KontaktPage
