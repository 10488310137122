import React from "react";
import PropTypes from "prop-types"

import "../contact-form/contact-form.sass"

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {formAction: ""}
    }

    componentDidMount() {
        this.setState({formAction: "https://formsubmit.io/send/47c8a99c-ce86-4173-a04b-ffdf228cd137"});
    }

    render() {
        return (
            <div
                className={'contact-form text-container left'}
            >
                <form action={this.state.formAction} method="POST">
                    <input
                        id="name"
                        name="_redirect"
                        type="hidden"
                        value="https://hgv-erdmannhausen.de/kontakt#success"
                    />

                    <label htmlFor="name"><p>Name*</p></label>
                    <input required type="text" id="name" name="name"/>
                    <label htmlFor="email"><p>Email*</p></label>
                    <input required type="text" id="email" name="email"/>
                    <label htmlFor="phone"><p>Telefon*</p></label>
                    <input required type="text" id="phone" name="phone"/>
                    <label htmlFor="message"><p>Nachricht*</p></label>
                    <textarea required type="text" id="message" name="message"/>
                    <button type="submit" className="secondary-button">Nachricht Senden</button>
                </form>
            </div>
        )
    }
}

ContactForm.propTypes = {
    children: PropTypes.node,
};

export default ContactForm
