import React from "react";
import PropTypes from "prop-types"

import "../hgv-address/hgv-address.sass"

class HgvAddress extends React.Component {


    render() {
        return (
            <>
            <div
                className={'hgv-address section bottom text-container'}
            >
                <h4 className={'subtitle'}>Adresse</h4>
                <p>Handels- und Gewerbeverein Erdmannhausen e.V.</p>
                <p>71729 Erdmannhausen</p>
                <p>Badstraße 8</p>
                <p>Telefon: <a href="phone:+4917621508689">0176 21508689</a></p>
                <p>E-Mail: <a href="mailto:info@hgv-erdmannhausen.de">info@hgv-erdmannhausen.de</a></p>
            </div>
                <div>
                    <iframe
                        title='Erdmannhausen Google Maps'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20967.244499352615!2d9.278596024709529!3d48.93624052488478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799cc4c4dcfce37%3A0x5ef20fccbd209392!2sErdmannhausen!5e0!3m2!1sde!2sde!4v1490598816295"
                        width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe></div>
                </>
        )
    }
}

HgvAddress.propTypes = {
    children: PropTypes.node,
};

export default HgvAddress
